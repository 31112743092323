.Diagnostics {
    margin-bottom: 25px;
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    h4 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 10px 0;
    }
}
