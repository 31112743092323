.FinishTests {
    margin: 50px 0;
    position: relative;
    h3 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 25px;
    }
    p {
        line-height: 22px;
        &:first-child{
            font-weight: 600;
            text-align: center;
        }
    }
    &__notProblem {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &__list {
        background-color: white;
        padding: 25px 5px;
        border: 1px solid rgb(133, 132, 132);
        border-radius: 5px;
        box-shadow: 0px 0px 10px black;
    }
    &__icon{
        display: flex;
        align-items: center;
            div{min-width: 44px;
            height: 40px;
            display: inline-block;
            border-radius: 5px;
            border: 2px solid rgb(133, 132, 132);
            background-color: aliceblue;
            img {
                width: 40px;
                height: 38px;
            }}
            p{
                margin: 0 7px;
            }
    }
    &__resault{
        margin-bottom: 25px;
    }
}
