.navbar {
    //для pc
    text-transform: uppercase;
    margin: 0 -10px 5px -10px;
    background-color: rgb(37, 37, 37);
    transition: left 0.3s ease;
    padding: 0 10px;
    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            transition: all 0.5s;
            color: white;
            cursor: pointer;
            &:hover {
                color: rgb(255, 217, 0);
            }
        }
        li {
            position: relative;
            .active {
                color: rgb(255, 0, 0);
            }
            .navbar__dropButton {
                color: white;
                background-color: rgba(37, 37, 37, 0);
                text-transform: uppercase;
                font-size: 16px;
                display: flex;
                align-items: center;
                transition: all 0.5s;
                &_downIcon {
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid white;
                    transition: transform 0.3s;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
                .border {
                    color: white;
                    display: inline-block;
                    background-color: grey;
                    padding: 4px;
                    margin: 0 5px;
                    border: 1px solid white;
                    border-radius: 6px;
                    transition: all 0.5s;
                    &.red {
                        color: red;
                    }
                    &.black {
                        color: black;
                    }
                }
                @media (min-width: 750px) {
                    &:hover {
                        color: rgb(255, 217, 0);
                        .border {
                            color: rgb(255, 217, 0);
                        }
                    }
                }
            }
        }
        .navList {
            max-height: 0px;
            width: 350px;
            transition: max-height 0.3s;
            overflow: hidden;
            position: absolute;
            top: 33px;
            left: -90px;
            display: flex;
            flex-direction: column;
            background-color: rgb(37, 37, 37);
            border-radius: 10px;
            z-index: 10;
            li {
                text-align: center;
                width: 100%;
                padding: 10px 0;
                &:last-child {
                    border-top: 1px solid black;
                }
            }
            &.open {
                max-height: 350px;
                border: 1px solid grey;
            }
        }
    }
    @media (max-width: 750px) {
        //коли диспей стане меньший зробити прихованим (появисться іконка для відображення)
        position: absolute;
        width: 100%;
        left: -100%;
        background-color: rgba(37, 37, 37, 0.877);
        backdrop-filter: blur(9px);
        height: 1000px;
        ul {
            flex-direction: column;
            width: 100%;
            li {
                padding: 0;
                font-size: 20px;
                width: 100%;
                .navbar__dropButton {
                    font-size: 20px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 20px 0;
                }
                >a {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 20px 0;
                }
            }
            .navList {
                top: 70px;
                left: 0;
                width: 100%;
                li {
                    padding: 0;
                }
            }
        }
    }
    &_active {
        //для mobile (коли нажметься іконка меню)
        left: 10px;
    }
}
