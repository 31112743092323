// @import url(https://fonts.googleapis.com/css?family=Unbounded:200,300,regular,500,600,700,800,900&display=swap&subset=cyrillic-ext);
body {
    background: linear-gradient(90deg, #f1e342 20%, #faa556 100%);
    // background: url('./img/icons/fon2.webp') 0 0/200% 7100px no-repeat;
    // @media(min-width: 1600px){
    //   background: url('./img/icons/fon2.webp') 0 0/100% 7100px no-repeat;
    // }
    font-family: sans-serif;
    letter-spacing: 1px;
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
    border: 0;
}
*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
:focus {
    // outline: none;
    //табуляція видна tab scrole
}
:active {
    outline: none;
}
a:focus {
    // outline: none;
    //табуляція видна tab scrole
}
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);//усунення ефекту синього бекграунда під час натискання на елемент на моб пристроях
    scrollbar-width: none;//забрати стандартний стиль скролу
    &::-webkit-scrollbar {
        width: 8px; //висота повзунка
    }
    &::-webkit-scrollbar-thumb {
        position: relative;
        background-color: #1b1b1b; /* колір "ползунка" скрола */
        border-radius: 4px; /* закруглені кути "ползунка" */
    }
    &::-webkit-scrollbar-track {
        background-color: rgb(255, 166, 0); /* Задайте колір поля скролбара */
    }
    scroll-behavior: smooth; /* Додаємо плавність скроллу */
    .root {
        width: 100%;
        height: 100%;
    }
}
input,
button,
textarea {
    font-family: inherit;
}
input::-ms-clear {
    display: none;
}
button {
    cursor: pointer;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
a,
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
ul li {
    list-style: none;
}
img {
    vertical-align: top;
}
P {
    cursor: default;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 14px;
    font-weight: 400;
    cursor: default;
    //вимкнення зміни вигляну мишки
}
