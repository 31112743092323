.App {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%; //default
    overflow: hidden; //default
    .contentApp {
        margin-top: 96px;
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:750px){
            margin-top: 83px;
        }
    }
}
.container {
    max-width: 1220px;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
.title {
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    @media (max-width: 1000px) {
        font-size: 20px;
    }
}
.btnY {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    background-color: #ffc107;
    padding: 0 30px;
    margin: 5px;
    height: 40px;
    border-radius: 10px;
    color: black;
    box-shadow: 0 0 5px rgb(0, 0, 0);
    position: relative;
    top: 0px;
    transition: all 0.3s;
    a {
        display: flex;
    }
    @media (min-width: 1000px) {
        //для екранів більше 1000
        &:active {
            background-color: #fcce03;
            box-shadow: 0px 0px 0px rgb(7, 7, 7);
            position: relative;
            top: 4px;
        }
    }
    &:hover {
        background-color: #fccb39;
    }
}
.none {
    display: none;
}
.react-slideshow-container {
    z-index: 7; //щоб стрілки не були поверх меню в road-foto
}
