.header {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9;
    background-color: rgb(37, 37, 37);
    &__icon {
        display: none;
        @media (max-width: 750px) {//поява іконки немю
            display: block;
            width: 40px;
            height: 28px;
            position: relative;
            cursor: pointer;
            span {
                top: 50%;
                left: 0;
                position: absolute;
                height: 10%;
                width: 100%;
                transition: all 0.3s ease 0s;
                transform: scale(1) translate(0px,-50%);
                background-color: rgb(252, 252, 252);
            }
            &::before {
                content: "";
                top: 0;
                left: 0;
                position: absolute;
                height: 10%;
                width: 100%;
                transition: all 0.3s ease 0s;
                background-color: rgb(255, 255, 255);
            }
            &::after {
                content: "";
                left: 0;
                bottom: 0;
                position: absolute;
                height: 10%;
                width: 100%;
                transition: all 0.3s ease 0s;
                background-color: rgb(255, 255, 255);
            }
            &.active{
                span{
                    transform: scale(0) translate(0px,-50%);
                }
                &::before{
                    top: 50%;
                    transform: rotate(-45deg) translate(0px, -50%);
                }
                &::after{
                    top: 50%;
                    transform: rotate(45deg) translate(0px, 50%);
                }
            }
        }
    }
    &__head {
        padding: 0;
        margin: 0 -10px 5px -10px;
        color: #fbe400;
        &_nav {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;
            @media (max-width: 750px) {
                //робимо лого та іконку меню по кутках
                //ТІЛЬКИ ДЛЯ ЕКРАНІВ МЕНЬШЕ 1000
                padding: 10px;
                justify-content: space-between;
            }
            .logo {
                display: block;
                img {
                    width: 100px;
                }
            }
        }
        &_location {
            display: flex;
            justify-content: space-around;
            margin-bottom: 8px;
            span {
                width: 33.3333%;
                text-align: center;
                cursor: default;
                a {
                    color: #fbe400;
                }
                @media (max-width: 750px) {
                    width: 100%;
                }
                &:first-child {
                    text-transform: uppercase;
                }
                &:last-child {
                    text-transform: uppercase;
                }
                @media (max-width: 750px) {
                    //приховуємо написи 'автосервіс' та 'луцьк'
                    &:first-child {
                        display: none;
                    }
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}
