.text {
    text-align: justify;
    line-height: 25px;
    margin-bottom: 25px;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
    &.red{
        color: red;
    }
    &.black{
        color: black;
    }
}
