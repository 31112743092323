@import "react-slideshow-image/dist/styles.css";

.banner {
    max-width: 1400px;
    margin: 0 auto;
    height: 700px;
    margin-bottom: 50px;
    div {
        width: 100%;
        height: 100%;
    }
    button {
        margin: 10px;
        position: relative;
    }
    .react-slideshow-container .nav {
        z-index: 7;
    }
}
.itemBanner {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 1400px auto;
    background-repeat: no-repeat;
    > div {
        background-color: rgba(7, 7, 7, 0.658);
        height: 100%;
    }
    h3 {
        color: white;
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        margin-top: 200px;
        margin-bottom: 25px;
    }
    p {
        color: white;
        text-align: center;
        margin-bottom: 25px;
    }
    &__nav {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-wrap: wrap;
        height: 300px;
        @media (max-width: 500px) {
            & {
                display: block;
                padding: 0 40px;
            }
        }
    }
    &_btnY {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 20px;
        background-color: #ffc107;
        border-radius: 10px;
        color: black;
        margin: 10px;
        @media (max-width: 376px) {
            font-size: 13px;
        }
    }
    &_btnR {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 20px;
        background-color: #dc3545;
        border-radius: 10px;
        color: white;
        margin: 10px;
        @media (max-width: 376px) {
            font-size: 13px;
        }
    }
}
