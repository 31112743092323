.instruction {
    width: 100%;
    margin-bottom: 20px;
    &__spoiler {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: rgb(185, 174, 70);
        button {
            background-color: rgba(254, 255, 240, 0);
            color: white;
            margin-right: 10px;
        }
        &_downIcon {
            display: block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid white;
            transition: transform 0.3s;
        }
        &.open {
            border-bottom: 1px solid rgb(145, 99, 1);
            .instruction__spoiler_downIcon {
                transform: rotate(180deg);
            }
        }
    }
    &__body {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
        img {
            width: 75%;
        }
        video {
            width: 50%;
            @media (max-width: 600px) {
                width: 90%;
            }
        }
        &.open {
            max-height: 1500px;
        }
    }
}
