@mixin timeTransition($time) {
    transition: all $time;
}
.service {
    width: 370px;
    padding: 10px;
    margin-bottom: 20px;
    position: relative;
    right: -3000px;
    transition: background-color 1s, border-radius 1s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.123);
        border-radius: 10px;
    }
    &__img1,&__img2,&__img3,&__img4,&__img5 {
        border-radius: 10px 10px 0 0;
        width: 100%;
        margin-bottom: 10px;
        height: 230px;
    }
    &__img1{
        background: url(./../../../../img/montage.jpg)50% 0/ auto 100% no-repeat ;
    }
    &__img2{
        background: url(./../../../../img/stDiaServ.webp)50% 0/ auto 100% no-repeat ;
    }
    &__img3{
        background: url(./../../../../img/altDiagServ.jpg)50% 0/ auto 100% no-repeat ;
    }
    &__img4{
        background: url(./../../../../img/batChan.jpg)50% 0/ auto 100% no-repeat ;
    }
    &__img5{
        background: url(./../../../../img/eltctr.jpg)50% 0/ auto 100% no-repeat ;
    }
    &__body {
        text-align: center;
        h5 {
            font-size: 19px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        p {
            line-height: 19px;
            margin-bottom: 10px;
            min-height: 80px;
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            color: rgb(0, 0, 0);
        }
    }
    &_1 {
        @include timeTransition(0.5s);
        @media (max-width: 1073px) {
            @include timeTransition(1.5s);
        }
    }
    &_2 {
        @include timeTransition(1s);
        @media (max-width: 1073px) {
            @include timeTransition(1.5s);
        }
    }
    &_3 {
        @include timeTransition(1.5s);
        @media (max-width: 1073px) {
            @include timeTransition(1.5s);
        }
    }
    &_4 {
        @include timeTransition(2s);
        @media (max-width: 1073px) {
            @include timeTransition(1.5s);
        }
    }
    &_5 {
        @include timeTransition(2.5s);
        @media (max-width: 1073px) {
            @include timeTransition(1.5s);
        }
    }
}
.service._active {
    opacity: 1;
    right: 0px;
}
