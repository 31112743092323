.blog {
    width: 389px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    transition: all 1s;
    &:hover {
        background-color: rgba(250, 249, 249, 0.178);
        border-radius: 10px;
    }
    a {
        margin: 0 auto;
    }
    div {
        flex: 1 1 auto;
        text-align: center;
        img {
            width: 100%;
            margin-bottom: 15px;
            border-radius: 50px;
            @media (min-width: 790px) {
                height: 280px;
            }
        }
        h5 {
            font-size: 19px;
            line-height: 19px;
            font-weight: 600;
            color: black;
            margin-bottom: 15px;
        }
        p {
            line-height: 19px;
            margin-bottom: 15px;
        }
    }
    
}
