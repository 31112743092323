.test {
      opacity: 0;
      max-width: 600px;
      flex: 1 1 600px;
      border-radius: 6px;
      box-shadow: 10px 10px 10px  rgb(245, 169, 4);
      text-align: center;
      padding: 0 20px 20px 20px;
      background-color: rgb(119, 97, 1);
      color: white;
      margin: 20px 0;
      transition: opacity 0.3s;
      h3{
            padding: 20px 0 10px 0 ;
            margin-bottom: 10px;
      }
      h4{
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 20px;
      }
      p{
            margin-bottom: 20px;
            line-height: 20px;
      }
      &.active{
            opacity: 1;
      }
      &__progress{
            margin-bottom: 20px;
      }
}

