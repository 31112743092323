.resaultDiagnostic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(133, 132, 132);
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px;
    background-color: rgb(241, 134, 134);
    transition: all 0.3s;
    div {
        min-width: 44px;
        height: 40px;
        display: inline-block;
        border-radius: 5px;
        border: 2px solid rgb(133, 132, 132);
        cursor: pointer;
        background-color: aliceblue;
        img {
            width: 40px;
            height: 38px;
        }
        &:hover {
            @media (min-width: 1000px) {
                //для екраїнів більше 1000
                transition: all 0.3s;
                border: 2px solid rgb(255, 196, 0);
                background: url("./../../../../../img/icons/che.webp") 0/100% no-repeat white;
            }
        }
    }
    p {
        margin: 0 0 0 10px;
        line-height: 20px;
    }
    &_active {
        background-color: rgba(0, 128, 0, 0.493);
    }
    &__btnDelete {
        padding: 5px;
        box-shadow: 0 0 2px 2px rgb(78, 78, 78);
        border-radius: 2px;
        &:hover {
            background-color: rgba(255, 0, 0, 0.548);
        }
    }
}
.noShow {
    position: relative;
    left: -2000px;
    transition: left 1s;
}
.show {
    position: relative;
    left: 0;
    max-height:1000px;
    overflow: hidden;
    transition: left 1s, max-height 1s;
}
.blur{
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.486);
    z-index: 9;
    backdrop-filter: blur(5px); 
    transition: background-color 1s;
}
