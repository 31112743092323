.NavToHome {
    &__logo {
        margin-bottom: 25px;
    }
    &__btns {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a{
            width: 230px;
        }
    }
}
.ok {
    display: flex;
    justify-content: center;
}
.YesOrNo {
    display: flex;
    justify-content: center;
    a {
        width: 93px;
    }
}
