.footer{
      background-color: rgb(37, 37, 37);
      p{
            color: white;
            padding: 5px;
            margin: 0;
            font-size: 10px;
            font-weight: 200;
            text-align: center;
            span{
                  color: aqua;
                  font-weight: 400;
            }
            @media(max-width: 800px){
                  font-size: 7px;
            }
      }
}