.description {
    h3 {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        margin: 50px 0;
        font-weight: 600;
    }
    &__question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        padding: 5px;
        background-color: rgb(252, 237, 173);
        border-radius: 5px 5px 5px 5px;
        transition: border-radius 1s;
        cursor: pointer;
        h4 {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            @media(max-width:440px){
                font-size: 16px;
            }
        }
        &_cross {
            width: 30px;
            height: 30px;
            position: relative;
            transition: transform 0.5s;
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 4px;
                background-color: rgb(216, 166, 3);
            }
            &:before {
                transform: translate(-50%, -50%);
            }
            &:after {
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        }
        &.open {
        border-bottom: 1px solid rgb(216, 166, 3);
        border-radius: 5px 5px 0px 0px;
            .description__question_cross {
                transform: rotate(-45deg);
            }
        }
    }
    &__respon {
        background-color: rgb(252, 237, 173);
        text-align: justify;
        line-height: 25px;
        margin-bottom: 15px;
        padding: 0 5px;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s;
        display: flex;
        align-items: center;
        &::first-letter {
            padding-left: 25px;
        }
        &.open {
            max-height: 1000px;
        }
    }
}
