.Item {
    width: 30%;
    min-width: 320px;
    padding: 15px 5px;
    background-color: white;
    margin: 0 5px 25px 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(245, 169, 4);
    border-radius: 10px;
    text-align: center;
    transition: opacity 2s, transform 2s;
    opacity: 0;
    transform: scale(1.3);
    
    h5 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }
    h6 {
        font-weight: 600;
        text-align: center;
        color: red;
        margin-bottom: 15px;
    }
    p {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
    @media (max-width: 680px) {
        width: 100%;
    }
    &.active {
        opacity: 1;
        transform: scale(1);
    }
}
