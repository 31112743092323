.review {
    // width: 30%;
    padding: 16px 20px;
    border-radius: 12px;
    background-color: white;
    margin: 5px;
    font-size: 14px;
    position: relative;
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
            margin-right: 10px;
            width: 32px;
        }
        span {
            font-weight: 600;
        }
    }
    &__stars {
        width: 90px;
        height: 16px;
        background: url("./../../../../img/icons/star.png") 0 0 / auto 100%;
        margin-bottom: 4px;
    }
    &__text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* Максимальна кількість рядків для відображення */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    &__link{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        border-radius: 12px;
        transition: background-color 0.3s;
        &:hover{
            background-color: rgba(255, 255, 255, 0.411);
        }
    }
}
