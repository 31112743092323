.services {
    width: 100%;
    border-top: 1px solid black;
    margin-bottom: 50px;
    &__title {
        margin: 50px 0;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}
