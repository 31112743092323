@mixin pBefore($url) {
    content: "";
    width: 40px;
    height: 30px;
    background: url($url) 0/100% no-repeat;
    top: 0;
    left: 0;
    position: absolute;
}
@mixin timeTransition($time) {
    transition: left $time;
}
.contact {
    border-top: 1px solid black;
    width: 100%;
    &__body {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        @media (max-width: 900px) {
            flex-direction: column;
            margin: 0 -20px;
        }
    }
    &__title {
        margin: 50px 0;
    }
    &__info {
        padding: 0 20px;
        width: 50%;
        @media (max-width: 900px) {
            width: 100%;
        }
        .textInfo {
            position: relative;
            left: -3000px;
            line-height: 30px;
            padding-left: 45px;
            margin-bottom: 15px;
            a {
                color: black;
            }
            li{
                font-weight: 300;
            }
            &_active {
                left: 0;
            }
            &_1 {
                @include timeTransition(1s);
            }
            &_2 {
                @include timeTransition(1.5s);
            }
            &_3 {
                @include timeTransition(2s);
            }
            &_4 {
                @include timeTransition(2.5s);
            }
            &_5 {
                @include timeTransition(3s);
            }
            &_6 {
                @include timeTransition(3.5s);
            }
        }
        ul:nth-child(1):before {
            content: "";
            width: 30px;
            height: 30px;
            background: url("./../../../img/icons/workSchedule.svg") 0/100% no-repeat;
            top: 0;
            left: 0;
            position: absolute;
        }
        p:nth-child(2):before {
            content: "";
            width: 20px;
            height: 36px;
            background: url("./../../../img/icons/phone.webp") 0/100% no-repeat;
            top: 0;
            left: 0;
            position: absolute;
        }
        p:nth-child(3):before {
            content: "";
            width: 40px;
            height: 40px;
            background: url("./../../../img/icons/place.webp") 0/100% no-repeat;
            top: 10px;
            left: 0;
            position: absolute;
        }
        p:nth-child(4):before {
            @include pBefore("./../../../img/icons/download.svg");
        }
        p:nth-child(5):before {
            @include pBefore("./../../../img/icons/download.svg");
        }
        p:nth-child(6):before {
            content: "";
            width: 35px;
            height: 35px;
            background: url("./../../../img/icons/teleg.webp") 0/100% no-repeat;
            top: 0;
            left: 0;
            position: absolute;
        }
    }
    &__map {
        padding: 0 10px;
        width: 50%;
        height: 400px;
        @media (max-width: 900px) {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    
}
