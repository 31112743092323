.batteryCharging {
    margin-bottom: 50px;
    &__title {
        margin: 50px 0 25px 0;
        line-height: 30px;
        @media (max-width: 620px) {
            & {
                font-size: 20px;
            }
        }
    }
    img {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 25px;
        display: block;
        @media (max-width: 620px) {
            & {
                width: 100%;
            }
        }
    }
    p {
        text-align: justify;
        line-height: 25px;
        &::first-letter {
            padding-left: 25px;
        }
    }
}
