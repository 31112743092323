

.features {
    width: 100%;
    margin-bottom: 25px;
    &__title {
        margin-bottom: 50px;
    }
    &__row {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}
