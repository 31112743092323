@import "react-slideshow-image/dist/styles.css";
.Slider {
    margin-bottom: 50px;
    &__title {
        margin: 50px 0;
    }
    &__item {
        img {
            cursor: pointer;
            width: 100%;
            border-radius: 20px;
        }
    }
    > div {
        max-width: 800px;
        margin: 0 auto;
        button {
            margin: 10px;
        }
    }
    @media (max-width: 820px) {
        & {
            margin:0 -15px 50px -15px;
            &__item {
                img {
                    border-radius: 0;
                }
            }
        }
    }
}
